.nameBadge {
    margin-left: 5px;
}

.nameBadge p {
    color: #fff;
}

.menuIcon {
    width: 25px !important;
    margin-right: 10px;
    font-size: 24px;
}

.menuItem {
    font-size: 18px;
    font-weight: 380;
    color: #ffffff;
}

.menuItem:hover {
    background-color: #1890ff !important;
}

.menuItemSelected {
    background-color: #0050b3 !important;
}

.welcomeMessage {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
}

.hospitalName {
    font-size: 16px;
    color: #bfbfbf;
}