.headerBar {
    background-color: #f59222;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #f59222;
}

.pageHeader {
    background-color: #488aa7;
}

.unitName,
.roomNumber,
.roundDate {
    color: #FFF;
}