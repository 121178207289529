.headerBar {
    background-color: #f59222;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.action-buttons {
    flex-direction: row;
}

.button-group {
    flex-direction: column;
}

@media (max-width: 768px) {
    .action-buttons {
        flex-direction: column;
        gap: 4px;
    }

    .button-group {
        flex-direction: row;
    }
}