.headerBar {
    background-color: #f59222;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #f59222;
}

.rounds {
    background-color: #dff0d8;
}

.rounds button {
    background-color: #f59222;
    color: #fff;
}

.rounds button svg {
    margin-bottom: 5px;
}

.ant-card {
    background-color: #dff0d8;
}