.headerBar {
    background-color: #f59222;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

a {
    color: blue;
    text-decoration: none;
    transition: color 0.3s ease;
}

a:hover {
    color: purple;
}